.Toastify__toast-body {
    font-size: 0.875rem !important;
    font-weight: 600 !important;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.Toastify__toast {
    min-height: fit-content !important;
    max-height: fit-content !important;
    margin-top: 10px !important;
}

.Toastify__progress-bar--wrp {
    height: 8px !important;
}

.Toastify__toast-container {
    /*width: 250px !important;*/
}

.Toastify__toast-container--bottom-center {
    bottom: 0 !important;
}

.Toastify__close-button {
    align-self: center !important;
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-bottom: 2px !important;
}

.Toastify__progress-bar--bg {
    opacity: 0.4 !important;
    background: #dee2e6 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    color: black !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning > .Toastify__close-button {
    color: black !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
    background: #ffc107 !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
    background: #ffc107 !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
    background: #0d6efd !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
    background: black !important;
}


.Toastify__toast-theme--colored.Toastify__toast--success {
    background: #198754 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
    background: #0dcaf0 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: #ffc107 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background: #dc3545 !important;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        padding: 10px 25px 15px 25px !important;
    }

    .Toastify__toast {
        border-radius: 6px !important;
    }
}

/*.Toastify__toast-theme--colored.Toastify__toast--error {*/
/*    color: black;*/
/*}*/

/*.Toastify__toast-theme--colored.Toastify__toast--error > .Toastify__close-button {*/
/*    color: black;*/
/*}*/

/*.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {*/
/*    background: black;*/
/*}*/