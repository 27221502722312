/* From Uiverse.io by ShadowShahriar */
.rainbow {
    --border-width: 2px;
    appearance: none;
    position: relative;
    border: 0;
    color: #fff;
    z-index: 2;
}

.rainbow::after {
    --m-i: linear-gradient(#000, #000);
    --m-o: content-box, padding-box;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: var(--border-width);
    border-radius: inherit;
    background-image: conic-gradient(
            #488cfb,
            #29dbbc,
            #ddf505,
            #ff9f0e,
            #e440bb,
            #655adc,
            #488cfb
    );
    -webkit-mask-image: var(--m-i), var(--m-i);
    mask-image: var(--m-i), var(--m-i);
    -webkit-mask-origin: var(--m-o);
    mask-origin: var(--m-o);
    -webkit-mask-clip: var(--m-o);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
    filter: hue-rotate(0);
    animation: rotate-hue linear 700ms infinite;
    animation-play-state: paused;
}

.rainbow::after {
    animation-play-state: running;
}

@keyframes rotate-hue {
    to {
        filter: hue-rotate(1turn);
    }
}

.rainbow,
.rainbow::after {
    box-sizing: border-box;
}

.rainbow:active {
    --border-width: 2px;
}