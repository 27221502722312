@import "toastify.css";
@import "custom.scss";
@import "dot.css";
@import "emergence.css";
@import "hello-assos.scss";
@import "shine.scss";
@import "whatsapp.css";

body {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}