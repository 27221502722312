.shine {
  position: relative;
  overflow: hidden;

  &::before {
    background: linear-gradient(to right,
            fade_out(#fff, 1) 0%,
            fade_out(#fff, 0.7) 100%
    );
    content: "";
    display: block;
    height: 100%;
    left: -95%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
    animation: shine 0.95s;
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }
}