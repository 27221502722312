$bd-purple:        #4c0bce;
$bd-violet:        lighten(saturate($bd-purple, 5%), 15%); // stylelint-disable-line function-disallowed-list

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

.btn-bd-custom {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #8c14fc;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($bd-violet, 10%)};
  --bs-btn-hover-border-color: #8c14fc;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
  --bs-btn-active-border-color: #{shade-color($bd-violet, 20%)};
}

.btn-bd-outline-custom {
  --bs-btn-color: #8c14fc;
  --bs-btn-border-color: #8c14fc;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #8c14fc;
  --bs-btn-hover-border-color: #8c14fc;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{shade-color($bd-violet, 20%)};
  --bs-btn-active-border-color: #{shade-color($bd-violet, 20%)};
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #{shade-color($bd-violet, 20%)};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{shade-color($bd-violet, 20%)};
}

.border-custom {
  border-color: #8c14fc;
}

.text-bg-custom {
  background-color: #8c14fc;
}

.btn-navbar {
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  color: var(--bs-navbar-color);
  font-family: inherit;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
}

.btn-navbar:active {
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  border-color: var(--bs-navbar-color);
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.btn-navbar:hover {
  border-color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
}

//::-webkit-scrollbar {
//  height: 5px;
//  width: 5px;
//}
//
//::-webkit-scrollbar-thumb {
//  background: gray;
//  border-radius: 10px;
//}
//
//#body{
//  padding-right: 0!important;
//  overflow: auto!important;
//}