@keyframes #{$rt-namespace}__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}

.#{$rt-namespace}__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);

  &--animated {
    animation: #{$rt-namespace}__trackProgress linear 1 forwards;
  }

  &--controlled {
    transition: transform 0.2s;
  }

  &--rtl {
    right: 0;
    left: initial;
    transform-origin: right;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: var(--toastify-toast-bd-radius);
  }

  &--wrp {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    border-bottom-left-radius: var(--toastify-toast-bd-radius);
  }

  &--wrp[data-hidden="true"] {
    opacity: 0;
  }

  &--bg {
    opacity: var(--toastify-color-progress-bgo);
    width: 100%;
    height: 100%;
  }
}
