.emergence {
    animation-duration: 0.5s;
    animation-name: emergence-fade;
    animation-delay: 0.2s;
    animation-fill-mode: backwards;
}

@keyframes emergence-fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}