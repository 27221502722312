.visible {
    /* display: flex; */
    transform: translateY(0px);
    transition: 0.4s ease-out;
}

.hidden {
    /* display: none; */
    transform: translateY(-286px);
    transition: 1.2s ease-out;
}

.hidden-top-bar {
    transform: translateY(-20px);
    transition: 0.2s ease-out;
}

.visible-top-bar {
    transform: translateY(0px);
    transition: 0.1s ease-out;
}