.auth-input {
    width: 325px;
}

.auth-input > input {
    text-align: left;
    letter-spacing: 2rem;
    transform: translate(18px)
}

.auth-input > input:focus {
    outline-style: none !important;
    box-shadow: none !important;
}
