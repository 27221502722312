.#{$rt-namespace}__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  &--top-left {
    top: var(--toastify-toast-top);
    left: var(--toastify-toast-left);
  }
  &--top-center {
    top: var(--toastify-toast-top);
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-right {
    top: var(--toastify-toast-top);
    right: var(--toastify-toast-right);
  }
  &--bottom-left {
    bottom: var(--toastify-toast-bottom);
    left: var(--toastify-toast-left);
  }
  &--bottom-center {
    bottom: var(--toastify-toast-bottom);
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: var(--toastify-toast-bottom);
    right: var(--toastify-toast-right);
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast-container {
    width: 100vw;
    padding: 0;
    left: env(safe-area-inset-left);;
    margin: 0;
    &--top-left,
    &--top-center,
    &--top-right {
      top: env(safe-area-inset-top);
      transform: translateX(0);
    }
    &--bottom-left,
    &--bottom-center,
    &--bottom-right {
      bottom: env(safe-area-inset-bottom);
      transform: translateX(0);
    }
    &--rtl {
      right: env(safe-area-inset-right);
      left: initial;
    }
  }
}
