.nav-theme-color {
  color: var(--bs-nav-link-color) !important;
}

.border-theme-color {
  border-color: rgba(var(--bs-body-color), var(--bs-border-opacity)) !important;
}

.dropup .hide-toggle.dropdown-toggle:after {
  display: none !important;
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}

.PresetDateRangePicker_button {
  text-align: center;
  color: #00a699;
  height: 100%;
  font: inherit;
  box-sizing: border-box;
  cursor: pointer;
  background: none;
  border: 2px solid #00a699;
  margin-right: 8px;
  padding: 4px 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
  overflow: visible;
}

.PresetDateRangePicker_button:active {
  outline: 0;
}

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699;
}

.SingleDatePickerInput {
  background-color: #fff;
  display: inline-block;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
}

.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__disabled {
  background-color: #f2f2f2;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput__showClearDate {
  padding-right: 30px;
}

.SingleDatePickerInput_clearDate {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 10px 0 5px;
  padding: 10px;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: visible;
  transform: translateY(-50%);
}

.SingleDatePickerInput_clearDate__default:focus, .SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}

.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 15px;
  height: 12px;
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}

.SingleDatePickerInput_calendarIcon {
  color: inherit;
  font: inherit;
  cursor: pointer;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 0 5px 0 10px;
  padding: 10px;
  line-height: normal;
  display: inline-block;
  overflow: visible;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 14px;
  height: 15px;
}

.SingleDatePicker {
  display: inline-block;
  position: relative;
}

.SingleDatePicker__block {
  display: block;
}

.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.SingleDatePicker_picker__rtl {
  direction: rtl;
}

.SingleDatePicker_picker__directionLeft {
  left: 0;
}

.SingleDatePicker_picker__directionRight {
  right: 0;
}

.SingleDatePicker_picker__portal {
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.SingleDatePicker_closeButton {
  color: inherit;
  font: inherit;
  cursor: pointer;
  z-index: 2;
  background: none;
  border: 0;
  padding: 15px;
  line-height: normal;
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
}

.SingleDatePicker_closeButton:focus, .SingleDatePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}

.SingleDatePicker_closeButton_svg {
  fill: #cacccd;
  width: 15px;
  height: 15px;
}

.DayPickerKeyboardShortcuts_buttonReset {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  font-size: 14px;
  line-height: normal;
  overflow: visible;
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_show {
  z-index: 2;
  width: 33px;
  height: 26px;
  position: absolute;
}

.DayPickerKeyboardShortcuts_show:before {
  content: "";
  display: block;
  position: absolute;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:before {
  border-top: 26px solid #0000;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:before {
  border-bottom: 26px solid #0000;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:hover:before {
  border-right: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:before {
  border-bottom: 26px solid #0000;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover:before {
  border-left: 33px solid #008489;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px;
}

.DayPickerKeyboardShortcuts_panel {
  z-index: 2;
  text-align: left;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  margin: 33px;
  padding: 22px;
  position: absolute;
  inset: 0;
  overflow: auto;
}

.DayPickerKeyboardShortcuts_title {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
}

.DayPickerKeyboardShortcuts_list {
  padding: 0;
  font-size: 14px;
  list-style: none;
}

.DayPickerKeyboardShortcuts_close {
  z-index: 2;
  position: absolute;
  top: 22px;
  right: 22px;
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_closeSvg {
  fill: #cacccd;
  width: 15px;
  height: 15px;
}

.DayPickerKeyboardShortcuts_closeSvg:focus, .DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a;
}

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.CalendarDay:active {
  outline: 0;
}

.CalendarDay__defaultCursor {
  cursor: default;
}

.CalendarDay__default {
  color: #484848;
  background: #fff;
  border: 1px solid #e4e7e7;
}

.CalendarDay__default:hover {
  color: inherit;
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
}

.CalendarDay__hovered_offset {
  color: inherit;
  background: #f4f5f5;
  border: 1px double #e4e7e7;
}

.CalendarDay__outside {
  color: #484848;
  background: #fff;
  border: 0;
}

.CalendarDay__outside:hover {
  border: 0;
}

.CalendarDay__blocked_minimum_nights {
  color: #cacccd;
  background: #fff;
  border: 1px solid #eceeee;
}

.CalendarDay__blocked_minimum_nights:active, .CalendarDay__blocked_minimum_nights:hover {
  color: #cacccd;
  background: #fff;
}

.CalendarDay__highlighted_calendar {
  color: #484848;
  background: #ffe8bc;
}

.CalendarDay__highlighted_calendar:active, .CalendarDay__highlighted_calendar:hover {
  color: #484848;
  background: #ffce71;
}

.CalendarDay__selected_span {
  color: #fff;
  background: #66e2da;
  border: 1px double #33dacd;
}

.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
  color: #fff;
  background: #33dacd;
  border: 1px double #33dacd;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  color: #fff;
  background: #00a699;
  border: 1px double #00a699;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:hover {
  color: #007a87;
  background: #b2f1ec;
  border: 1px double #80e8e0;
}

.CalendarDay__hovered_span:active {
  color: #007a87;
  background: #80e8e0;
  border: 1px double #80e8e0;
}

.CalendarDay__blocked_calendar, .CalendarDay__blocked_calendar:active, .CalendarDay__blocked_calendar:hover {
  color: #82888a;
  background: #cacccd;
  border: 1px solid #cacccd;
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover {
  color: #cacccd;
  background: #fff;
  border: 1px solid #e4e7e7;
}

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee;
}

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7;
}

.CalendarMonth {
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  user-select: none;
  background: #fff;
}

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0;
}

.CalendarMonth_verticalSpacing {
  border-collapse: separate;
}

.CalendarMonth_caption {
  color: #484848;
  text-align: center;
  caption-side: initial;
  padding-top: 22px;
  padding-bottom: 37px;
  font-size: 18px;
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}

.CalendarMonthGrid {
  text-align: left;
  z-index: 0;
  background: #fff;
}

.CalendarMonthGrid__animating {
  z-index: 1;
}

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px;
}

.CalendarMonthGrid__vertical, .CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
}

.CalendarMonthGrid_month__horizontal {
  vertical-align: top;
  min-height: 100%;
  display: inline-block;
}

.CalendarMonthGrid_month__hideForAnimation {
  z-index: -1;
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden;
}

.DayPickerNavigation {
  z-index: 2;
  position: relative;
}

.DayPickerNavigation__horizontal {
  height: 0;
}

.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1;
}

.DayPickerNavigation__verticalDefault {
  width: 100%;
  height: 52px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}

.DayPickerNavigation__bottom {
  height: auto;
}

.DayPickerNavigation__bottomDefault {
  justify-content: space-between;
  display: flex;
}

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.DayPickerNavigation_button__default {
  color: #757575;
  background-color: #fff;
  border: 1px solid #e4e7e7;
}

.DayPickerNavigation_button__default:focus, .DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4;
}

.DayPickerNavigation_button__default:active {
  background: #f2f2f2;
}

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2;
}

.DayPickerNavigation_button__disabled:focus, .DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2;
}

.DayPickerNavigation_button__disabled:active {
  background: none;
}

.DayPickerNavigation_button__horizontalDefault {
  border-radius: 3px;
  padding: 6px 9px;
  line-height: .78;
  position: absolute;
  top: 18px;
}

.DayPickerNavigation_bottomButton__horizontalDefault {
  margin: -10px 22px 30px;
  position: static;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px;
}

.DayPickerNavigation_button__verticalDefault {
  text-align: center;
  background: #fff;
  width: 50%;
  height: 100%;
  padding: 5px;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 5px 2px #0000001a;
}

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}

.DayPickerNavigation_nextButton__verticalScrollableDefault, .DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%;
}

.DayPickerNavigation_svg__horizontal {
  fill: #82888a;
  width: 19px;
  height: 19px;
  display: block;
}

.DayPickerNavigation_svg__vertical {
  fill: #484848;
  width: 42px;
  height: 42px;
}

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2;
}

.DayPicker {
  text-align: left;
  background: #fff;
  position: relative;
}

.DayPicker__horizontal {
  background: #fff;
}

.DayPicker__verticalScrollable {
  height: 100%;
}

.DayPicker__hidden {
  visibility: hidden;
}

.DayPicker__withBorder {
  border-radius: 3px;
  box-shadow: 0 2px 6px #0000000d, 0 0 0 1px #00000012;
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.DayPicker_portal__vertical {
  position: initial;
}

.DayPicker_focusRegion {
  outline: 0;
}

.DayPicker_calendarInfo__horizontal, .DayPicker_wrapper__horizontal {
  vertical-align: top;
  display: inline-block;
}

.DayPicker_weekHeaders {
  position: relative;
}

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px;
}

.DayPicker_weekHeader {
  color: #757575;
  z-index: 2;
  text-align: left;
  position: absolute;
  top: 62px;
}

.DayPicker_weekHeader__vertical {
  left: 50%;
}

.DayPicker_weekHeader__verticalScrollable {
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  margin-left: 0;
  display: table-row;
  top: 0;
  left: 0;
}

.DayPicker_weekHeader_ul {
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  list-style: none;
}

.DayPicker_weekHeader_li {
  text-align: center;
  display: inline-block;
}

.DayPicker_transitionContainer {
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out;
}

.DayPicker_transitionContainer__vertical {
  width: 100%;
}

.DayPicker_transitionContainer__verticalScrollable {
  height: 100%;
  padding-top: 20px;
  position: absolute;
  inset: 0;
  overflow-y: scroll;
}

.DateInput {
  vertical-align: middle;
  background: #fff;
  width: 130px;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.DateInput__small {
  width: 97px;
}

.DateInput__block {
  width: 100%;
}

.DateInput__disabled {
  color: #dbdbdb;
  background: #f2f2f2;
}

.DateInput_input {
  color: #484848;
  background-color: #fff;
  border: 0;
  border-bottom: 2px solid #0000;
  border-radius: 0;
  width: 100%;
  padding: 11px 11px 9px;
  font-size: 19px;
  font-weight: 200;
  line-height: 24px;
}

.DateInput_input__small {
  letter-spacing: .2px;
  padding: 7px 7px 5px;
  font-size: 15px;
  line-height: 18px;
}

.DateInput_input__regular {
  font-weight: auto;
}

.DateInput_input__readOnly {
  -webkit-user-select: none;
  user-select: none;
}

.DateInput_input__focused {
  background: #fff;
  border: 0;
  border-bottom: 2px solid #008489;
  outline: 0;
}

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic;
}

.DateInput_screenReaderMessage {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.DateInput_fang {
  z-index: 2;
  width: 20px;
  height: 10px;
  position: absolute;
  left: 22px;
}

.DateInput_fangShape {
  fill: #fff;
}

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: #0000;
}

.DateRangePickerInput {
  background-color: #fff;
  display: inline-block;
}

.DateRangePickerInput__disabled {
  background: #f2f2f2;
}

.DateRangePickerInput__withBorder {
  border: 1px solid #dbdbdb;
  border-radius: 2px;
}

.DateRangePickerInput__rtl {
  direction: rtl;
}

.DateRangePickerInput__block {
  display: block;
}

.DateRangePickerInput__showClearDates {
  padding-right: 30px;
}

.DateRangePickerInput_arrow {
  vertical-align: middle;
  color: #484848;
  display: inline-block;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  width: 24px;
  height: 24px;
}

.DateRangePickerInput_clearDates {
  color: inherit;
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0 10px 0 5px;
  padding: 10px;
  line-height: normal;
  position: absolute;
  top: 50%;
  right: 0;
  overflow: visible;
  transform: translateY(-50%);
}

.DateRangePickerInput_clearDates__small {
  padding: 6px;
}

.DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%;
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 15px;
  height: 12px;
}

.DateRangePickerInput_clearDates_svg__small {
  height: 9px;
}

.DateRangePickerInput_calendarIcon {
  color: inherit;
  font: inherit;
  cursor: pointer;
  vertical-align: middle;
  background: none;
  border: 0;
  margin: 0 5px 0 10px;
  padding: 10px;
  line-height: normal;
  display: inline-block;
  overflow: visible;
}

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  vertical-align: middle;
  width: 14px;
  height: 15px;
}

.DateRangePicker {
  display: inline-block;
  position: relative;
}

.DateRangePicker__block {
  display: block;
}

.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute;
}

.DateRangePicker_picker__rtl {
  direction: rtl;
}

.DateRangePicker_picker__directionLeft {
  left: 0;
}

.DateRangePicker_picker__directionRight {
  right: 0;
}

.DateRangePicker_picker__portal {
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff;
}

.DateRangePicker_closeButton {
  color: inherit;
  font: inherit;
  cursor: pointer;
  z-index: 2;
  background: none;
  border: 0;
  padding: 15px;
  line-height: normal;
  position: absolute;
  top: 0;
  right: 0;
  overflow: visible;
}

.DateRangePicker_closeButton:focus, .DateRangePicker_closeButton:hover {
  color: darken(#cacccd, 10%);
  text-decoration: none;
}

.DateRangePicker_closeButton_svg {
  fill: #cacccd;
  width: 15px;
  height: 15px;
}

.uiverse {
  --duration: 7s;
  --easing: linear;
  --c-color-1: #1aa3ffb3;
  --c-color-2: #cd1aff;
  --c-color-3: #ff1a75;
  --c-color-4: #ffd91ab3;
  --c-shadow: #57dfff80;
  --c-shadow-inset-top: #34dfffe6;
  --c-shadow-inset-bottom: #d7faffcc;
  --c-radial-inner: #15d2ff;
  --c-radial-outer: #72faff;
  --c-color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
  text-align: center;
  letter-spacing: .02em;
  color: #f5f6fa;
  background: radial-gradient(circle, var(--c-radial-inner), var(--c-radial-outer) 80%);
  box-shadow: 0 0 14px var(--c-shadow);
  border: none;
  border-radius: .25rem;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5;
  display: table;
  position: relative;
}

.uiverse:before {
  content: "";
  pointer-events: none;
  z-index: 3;
  box-shadow: inset 0 3px 12px var(--c-shadow-inset-top), inset 0 -3px 4px var(--c-shadow-inset-bottom);
  border-radius: .25rem;
  position: absolute;
  inset: 0;
}

.uiverse:hover {
  --c-color-1: #ff5252;
  --c-color-2: #00bcd4;
  --c-color-3: #9c27b0;
  --c-color-4: #ffeb3b;
}

.uiverse:active {
  --c-color-1: #ff5252;
  --c-color-2: #00bcd4;
  --c-color-3: #9c27b0;
  --c-color-4: #ffeb3b;
  box-shadow: none;
}

.uiverse:disabled {
  --c-color-1: none;
  --c-color-2: none;
  --c-color-3: none;
  --c-color-4: none;
  box-shadow: none;
  color: #636363;
  background: #72faff;
  animation: none;
  transform: none;
}

.uiverse .wrapper {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  padding: .5rem;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(#fff, #000);
}

.uiverse .wrapper span {
  z-index: 1;
  align-content: center;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;
}

.uiverse .wrapper .circle {
  border-radius: inherit;
  width: 40px;
  height: 40px;
  filter: blur(var(--blur, 8px));
  background: var(--background, transparent);
  transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
  animation: var(--animation, none) var(--duration) var(--easing) infinite;
  position: absolute;
  top: 0;
  left: 0;
}

.uiverse .wrapper .circle.circle-6, .uiverse .wrapper .circle.circle-9, .uiverse .wrapper .circle.circle-10 {
  --background: var(--c-color-4);
}

.uiverse .wrapper .circle.circle-3, .uiverse .wrapper .circle.circle-4 {
  --background: var(--c-color-2);
  --blur: 14px;
}

.uiverse .wrapper .circle.circle-5, .uiverse .wrapper .circle.circle-1 {
  --background: var(--c-color-3);
  --blur: 16px;
}

.uiverse .wrapper .circle.circle-2, .uiverse .wrapper .circle.circle-7, .uiverse .wrapper .circle.circle-8, .uiverse .wrapper .circle.circle-11, .uiverse .wrapper .circle.circle-12 {
  --background: var(--c-color-1);
  --blur: 12px;
}

.uiverse .wrapper .circle.circle-1 {
  --x: 0;
  --y: -40px;
  --animation: circle-1;
}

.uiverse .wrapper .circle.circle-2 {
  --x: 92px;
  --y: 8px;
  --animation: circle-2;
}

.uiverse .wrapper .circle.circle-3 {
  --x: -12px;
  --y: -12px;
  --animation: circle-3;
}

.uiverse .wrapper .circle.circle-4 {
  --x: 150px;
  --y: -12px;
  --animation: circle-4;
}

.uiverse .wrapper .circle.circle-5 {
  --x: 12px;
  --y: -4px;
  --animation: circle-5;
}

.uiverse .wrapper .circle.circle-6 {
  --x: 150px;
  --y: 16px;
  --animation: circle-6;
}

.uiverse .wrapper .circle.circle-7 {
  --x: 8px;
  --y: 28px;
  --animation: circle-7;
}

.uiverse .wrapper .circle.circle-8 {
  --x: 28px;
  --y: -4px;
  --animation: circle-8;
}

.uiverse .wrapper .circle.circle-9 {
  --x: 20px;
  --y: -12px;
  --animation: circle-9;
}

.uiverse .wrapper .circle.circle-10 {
  --x: 150px;
  --y: 16px;
  --animation: circle-10;
}

.uiverse .wrapper .circle.circle-11 {
  --x: 4px;
  --y: 4px;
  --animation: circle-11;
}

.uiverse .wrapper .circle.circle-12 {
  --blur: 14px;
  --x: 52px;
  --y: 4px;
  --animation: circle-12;
}

@keyframes circle-1 {
  33% {
    transform: translate(0, 16px)translateZ(0);
  }

  66% {
    transform: translate(12px, 64px)translateZ(0);
  }
}

@keyframes circle-2 {
  33% {
    transform: translate(80px, -10px)translateZ(0);
  }

  66% {
    transform: translate(72px, -48px)translateZ(0);
  }
}

@keyframes circle-3 {
  33% {
    transform: translate(20px, 12px)translateZ(0);
  }

  66% {
    transform: translate(12px, 4px)translateZ(0);
  }
}

@keyframes circle-4 {
  33% {
    transform: translate(76px, -12px)translateZ(0);
  }

  66% {
    transform: translate(112px, -8px)translateZ(0);
  }
}

@keyframes circle-5 {
  33% {
    transform: translate(84px, 28px)translateZ(0);
  }

  66% {
    transform: translate(40px, -32px)translateZ(0);
  }
}

@keyframes circle-6 {
  33% {
    transform: translate(0, -1px)translateZ(0);
  }

  66% {
    transform: translate(76px, -26px)translateZ(0);
  }
}

@keyframes circle-7 {
  33% {
    transform: translate(8px, 28px)translateZ(0);
  }

  66% {
    transform: translate(20px, -60px)translateZ(0);
  }
}

@keyframes circle-8 {
  33% {
    transform: translate(32px, -4px)translateZ(0);
  }

  66% {
    transform: translate(56px, -20px)translateZ(0);
  }
}

@keyframes circle-9 {
  33% {
    transform: translate(20px, -12px)translateZ(0);
  }

  66% {
    transform: translate(80px, -8px)translateZ(0);
  }
}

@keyframes circle-10 {
  33% {
    transform: translate(68px, 20px)translateZ(0);
  }

  66% {
    transform: translate(100px, 28px)translateZ(0);
  }
}

@keyframes circle-11 {
  33% {
    transform: translate(4px, 4px)translateZ(0);
  }

  66% {
    transform: translate(68px, 20px)translateZ(0);
  }
}

@keyframes circle-12 {
  33% {
    transform: translate(56px)translateZ(0);
  }

  66% {
    transform: translate(60px, -32px)translateZ(0);
  }
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 10px;
}

.bd-placeholder-img {
  text-anchor: middle;
  -webkit-user-select: none;
  user-select: none;
  font-size: 1.125rem;
}

@media (width >= 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  background-color: #0000001a;
  border: 1px solid #00000026;
  border-width: 1px 0;
  width: 100%;
  height: 3rem;
  box-shadow: inset 0 .5em 1.5em #0000001a, inset 0 .125em .5em #00000026;
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  z-index: 2;
  height: 2.75rem;
  position: relative;
  overflow-y: hidden;
}

.nav-scroller .nav {
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  flex-wrap: nowrap;
  margin-top: -1px;
  padding-bottom: 1rem;
  display: flex;
  overflow-x: auto;
}

.bd-mode-toggle {
  z-index: 1500;
}

.SingleDatePickerInput {
  border: 1px solid #6c757d !important;
  border-radius: 7px !important;
}

.SingleDatePickerInput__withBorder {
  border: var(--bs-border-width) solid var(--bs-border-color);
  text-align: start;
  border-radius: 7px;
  background-color: var(--bs-body-bg) !important;
}

.DateInput {
  vertical-align: middle;
  background: #fff;
  border-radius: 7px;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.DateInput_input {
  color: #021d2f !important;
  text-align: left !important;
  background-color: #fff !important;
  border: 0 !important;
  border-bottom: 2px solid #0000 !important;
  border-radius: 7px !important;
  max-height: 36px !important;
  padding: 11px 11px 9px !important;
  font-family: inherit !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 15px !important;
}

.DateRangePickerInput__withBorder {
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
  color: #fff !important;
  border: var(--bs-border-width) double #000 !important;
  background: #000 !important;
}

.DateInput_input__focused {
  background: #fff !important;
  outline: 0 !important;
}

.DateRangePickerInput__withBorder {
  text-align: center !important;
  background-color: var(--bs-body-bg) !important;
  box-shadow: var(--bs-box-shadow-sm) !important;
  border-radius: 7px !important;
}

.DateRangePickerInput_clearDates {
  border-left: 1px solid #6c757d !important;
  border-radius: 0 .375rem .375rem 0 !important;
  width: 42px !important;
  margin: auto !important;
  padding-top: 7px !important;
}

.DateRangePickerInput_clearDates:hover {
  background-color: #6c757d !important;
  border-radius: 0 .375rem .375rem 0 !important;
  height: 36px !important;
  padding-top: 7px !important;
}

.DateRangePickerInput_clearDates path:hover, .DateRangePickerInput_clearDates_svg path:hover, .DateRangePickerInput_clearDates_svg_1 path:hover {
  fill: #fff !important;
}

@media (width >= 360px) {
  .DateInput_input_1 {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 115px !important;
    color: var(--bs-body-color) !important;
    border-radius: 7px !important;
  }

  .DateInput {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 115px !important;
  }
}

@media (width >= 361px) {
  .DateInput_input_1 {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 122px !important;
    color: var(--bs-body-color) !important;
    border-radius: 7px !important;
  }

  .DateInput {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 122px !important;
  }
}

@media (width >= 390px) {
  .DateInput_input_1 {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 128px !important;
    color: var(--bs-body-color) !important;
    border-radius: 7px !important;
  }

  .DateInput {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 128px !important;
  }
}

@media (width >= 414px) {
  .DateInput_input_1 {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 130px !important;
    color: var(--bs-body-color) !important;
    border-radius: 7px !important;
  }

  .DateInput {
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
    max-width: 130px !important;
  }
}

@media (width >= 500px) {
  .DateInput_input_1 {
    text-align: start !important;
    max-width: inherit !important;
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
    border-radius: 7px !important;
  }

  .DateInput {
    max-width: inherit !important;
    text-align: start !important;
    background-color: var(--bs-body-bg) !important;
  }
}

.DayPicker_transitionContainer {
  max-width: 100%;
  overflow-x: hidden;
  box-shadow: var(--bs-box-shadow) !important;
}

.file-input {
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.rainbow {
  --border-width: 2px;
  appearance: none;
  color: #fff;
  z-index: 2;
  border: 0;
  position: relative;
}

.rainbow:after {
  --m-i: linear-gradient(#000, #000);
  --m-o: content-box, padding-box;
  content: "";
  width: 100%;
  height: 100%;
  padding: var(--border-width);
  border-radius: inherit;
  -webkit-mask-image: var(--m-i), var(--m-i);
  mask-image: var(--m-i), var(--m-i);
  -webkit-mask-origin: var(--m-o);
  mask-origin: var(--m-o);
  -webkit-mask-clip: var(--m-o);
  -webkit-mask-composite: destination-out;
  filter: hue-rotate();
  background-image: conic-gradient(#488cfb, #29dbbc, #ddf505, #ff9f0e, #e440bb, #655adc, #488cfb);
  animation: .7s linear infinite rotate-hue;
  position: absolute;
  top: 0;
  left: 0;
  mask-composite: exclude;
}

@keyframes rotate-hue {
  to {
    filter: hue-rotate(1turn);
  }
}

.rainbow, .rainbow:after {
  box-sizing: border-box;
}

.rainbow:active {
  --border-width: 2px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-offset: 16px;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
  --toastify-toast-right: max(var(--toastify-toast-offset), env(safe-area-inset-right));
  --toastify-toast-left: max(var(--toastify-toast-offset), env(safe-area-inset-left));
  --toastify-toast-bottom: max(var(--toastify-toast-offset), env(safe-area-inset-bottom));
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-toast-bd-radius: 6px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  --toastify-color-progress-bgo: .2;
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: var(--toastify-toast-top);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--top-center {
  top: var(--toastify-toast-top);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: var(--toastify-toast-top);
  right: var(--toastify-toast-right);
}

.Toastify__toast-container--bottom-left {
  bottom: var(--toastify-toast-bottom);
  left: var(--toastify-toast-left);
}

.Toastify__toast-container--bottom-center {
  bottom: var(--toastify-toast-bottom);
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: var(--toastify-toast-bottom);
  right: var(--toastify-toast-right);
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    left: env(safe-area-inset-left);
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: env(safe-area-inset-top);
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: env(safe-area-inset-bottom);
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: env(safe-area-inset-right);
    left: initial;
  }
}

.Toastify__toast {
  --y: 0;
  -ms-touch-action: none;
  touch-action: none;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  border-radius: var(--toastify-toast-bd-radius);
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  z-index: 0;
  direction: ltr;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px #0000001a;
}

.Toastify__toast--stacked {
  width: 100%;
  transform: translate3d(0, var(--y), 0) scale(var(--s));
  transition: transform .3s;
  position: absolute;
}

.Toastify__toast--stacked[data-collapsed] .Toastify__toast-body, .Toastify__toast--stacked[data-collapsed] .Toastify__close-button {
  transition: opacity .1s;
}

.Toastify__toast--stacked[data-collapsed="false"] {
  overflow: visible;
}

.Toastify__toast--stacked[data-collapsed="true"]:not(:last-child) > * {
  opacity: 0;
}

.Toastify__toast--stacked:after {
  content: "";
  height: calc(var(--g) * 1px);
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
}

.Toastify__toast--stacked[data-pos="top"] {
  top: 0;
}

.Toastify__toast--stacked[data-pos="bot"] {
  bottom: 0;
}

.Toastify__toast--stacked[data-pos="bot"].Toastify__toast--stacked:before {
  transform-origin: top;
}

.Toastify__toast--stacked[data-pos="top"].Toastify__toast--stacked:before {
  transform-origin: bottom;
}

.Toastify__toast--stacked:before {
  content: "";
  z-index: -1;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scaleY(3);
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .5s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  z-index: 1;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  width: 100%;
  height: 100%;
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
  border-bottom-left-radius: initial;
  border-bottom-right-radius: var(--toastify-toast-bd-radius);
}

.Toastify__progress-bar--wrp {
  border-bottom-left-radius: var(--toastify-toast-bd-radius);
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--wrp[data-hidden="true"] {
  opacity: 0;
}

.Toastify__progress-bar--bg {
  opacity: var(--toastify-color-progress-bgo);
  width: 100%;
  height: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, var(--y), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, var(--y), 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, calc(var(--y)  - 10px), 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, calc(var(--y)  + 20px), 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: translate3d(0, var(--y), 0) scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: translate3d(0, var(--y), 0) perspective(400px);
  }

  30% {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: translate3d(0, var(--y), 0) perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, var(--y), 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, var(--y), 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, var(--y), 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
  animation-duration: .3s;
  animation-timing-function: ease-in;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast-body {
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
  font-size: .875rem !important;
  font-weight: 600 !important;
}

.Toastify__toast {
  min-height: fit-content !important;
  max-height: fit-content !important;
  margin-top: 10px !important;
}

.Toastify__progress-bar--wrp {
  height: 8px !important;
}

.Toastify__toast-container--bottom-center {
  bottom: 0 !important;
}

.Toastify__close-button {
  align-self: center !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.Toastify__progress-bar--bg {
  opacity: .4 !important;
  background: #dee2e6 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning, .Toastify__toast-theme--colored.Toastify__toast--warning > .Toastify__close-button {
  color: #000 !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
  background: #ffc107 !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
  background: #0d6efd !important;
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: #000 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #198754 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #0dcaf0 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #ffc107 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #dc3545 !important;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    padding: 10px 25px 15px !important;
  }

  .Toastify__toast {
    border-radius: 6px !important;
  }
}

.btn-bd-custom {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #8c14fc;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #8c14fc;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.btn-bd-outline-custom {
  --bs-btn-color: #8c14fc;
  --bs-btn-border-color: #8c14fc;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #8c14fc;
  --bs-btn-hover-border-color: #8c14fc;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #5a23c8;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5a23c8;
}

.border-custom {
  border-color: #8c14fc;
}

.text-bg-custom {
  background-color: #8c14fc;
}

.btn-navbar {
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  font-family: inherit;
}

.btn-navbar:active {
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), .65);
  border-color: var(--bs-navbar-color);
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.btn-navbar:hover {
  border-color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
}

@keyframes dots-1 {
  from {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }
}

@keyframes dots-2 {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes dots-3 {
  from {
    opacity: 0;
  }

  75% {
    opacity: 1;
  }
}

.dots span {
  animation: 1s step-end infinite dots-1;
  -webkit-animation: 3s step-end infinite dots-1;
}

.dots span:first-child + span {
  animation-name: dots-2;
}

.dots span:first-child + span + span {
  animation-name: dots-3;
}

.emergence {
  animation-name: emergence-fade;
  animation-duration: .5s;
  animation-delay: .2s;
  animation-fill-mode: backwards;
}

@keyframes emergence-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.border-ha {
  border-color: #49d38a;
}

.HaAuthorizeButton {
  border-radius: var(--bs-border-radius);
  background-color: #fff;
  border: .0625rem solid #49d38a;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.HaAuthorizeButton:disabled {
  cursor: not-allowed;
  background-color: #e9e9f0;
  border-color: #0000;
}

.HaAuthorizeButton:not(:disabled):focus {
  box-shadow: 0 0 0 .25rem #49d38a40;
}

.HaAuthorizeButtonLogo {
  width: 2.25rem;
  padding: 0 .8rem;
}

.HaAuthorizeButtonTitle {
  color: #fff;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  background-color: #49d38a;
  border-radius: 0 .3rem .3rem 0;
  width: 100%;
  padding: .375rem 1rem;
}

.HaAuthorizeButton:disabled .HaAuthorizeButtonTitle {
  color: #9a9da8;
  background-color: #e9e9f0;
}

.HaAuthorizeButton:not(:disabled):hover .HaAuthorizeButtonTitle, .HaAuthorizeButton:not(:disabled):focus .HaAuthorizeButtonTitle {
  background-color: #30c677;
}

.HaAuthorizeButtonInvert {
  border-radius: var(--bs-border-radius);
  background-color: #fff;
  border: .0625rem solid #b62c75;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.HaAuthorizeButtonInvert:not(:disabled):hover .HaAuthorizeButtonInvertTitle, .HaAuthorizeButtonInvert:not(:disabled):focus .HaAuthorizeButtonInvertTitle {
  background-color: #cf3988;
}

.HaAuthorizeButtonInvert:disabled {
  cursor: not-allowed;
  background-color: #e9e9f0;
  border-color: #0000;
}

.HaAuthorizeButtonInvert:disabled .HaAuthorizeButtonInvertTitle {
  color: #9a9da8;
  background-color: #e9e9f0;
}

.HaAuthorizeButtonInvert:not(:disabled):focus {
  box-shadow: 0 0 0 .25rem #b62c7540;
}

.HaAuthorizeButtonInvertTitle {
  color: #fff;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  background-color: #b62c75;
  border-radius: .3rem;
  width: 100%;
  padding: .375rem 1rem;
}

.HaPaymentButton {
  border-radius: var(--bs-border-radius);
  background-color: #fff;
  border: .0625rem solid #4d3fcf;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  padding: 0;
}

.HaPaymentButton:hover {
  border-radius: var(--bs-border-radius);
  background-color: #fff;
  border: .0625rem solid #31278c;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  align-items: center;
  padding: 0;
}

.HaPaymentButton:active {
  border: .0625rem solid #4b40a6;
}

.HaPaymentButton:disabled {
  cursor: not-allowed;
  background-color: #e9e9f0;
  border-color: #0000;
}

.HaPaymentButtonTitle {
  color: #fff;
  line-height: var(--bs-btn-line-height);
  background-color: #4d3fcf;
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  width: 100%;
  padding: .375rem 1rem;
}

.HaPaymentButtonTitle:hover {
  background-color: #31278c;
}

.HaPaymentButtonTitle:active {
  background-color: #4b40a6;
}

.HaPaymentButton[disabled] .HaPaymentButtonTitle {
  color: #9a9da8;
  background-color: #e9e9f0;
}

.HaPaymentButton[disabled] > img {
  opacity: .5;
}

.shine {
  position: relative;
  overflow: hidden;
}

.shine:before {
  content: "";
  z-index: 2;
  background: linear-gradient(to right, #fff0 0%, #ffffff4d 100%);
  width: 50%;
  height: 100%;
  animation: .95s shine;
  display: block;
  position: absolute;
  top: 0;
  left: -95%;
  transform: skewX(-25deg);
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.whatsapp-button {
  color: #fff;
  background-color: #0f7a6e;
}

.whatsapp-button:hover {
  color: #fff;
  background-color: #075e54;
}

.whatsapp-button:active {
  color: #fff !important;
  background-color: #128c7e !important;
}

body {
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  height: 100vh;
  overflow: hidden scroll;
}

.visible {
  transition: all .4s ease-out;
  transform: translateY(0);
}

.hidden {
  transition: all 1.2s ease-out;
  transform: translateY(-286px);
}

.hidden-top-bar {
  transition: all .2s ease-out;
  transform: translateY(-20px);
}

.visible-top-bar {
  transition: all .1s ease-out;
  transform: translateY(0);
}

.auth-input {
  width: 325px;
}

.auth-input > input {
  text-align: left;
  letter-spacing: 2rem;
  transform: translate(18px);
}

.auth-input > input:focus {
  box-shadow: none !important;
  outline-style: none !important;
}

.container_SevMini {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Ghost {
  z-index: -1;
  animation: 4s ease-in-out infinite opacidad;
  transform: translate(0, -25px);
}

@keyframes opacidad {
  0% {
    opacity: 1;
    scale: 1;
  }

  50% {
    opacity: .5;
    scale: .9;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

@keyframes estroboscopico {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rebote {
  0%, 100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes estroboscopico1 {
  0%, 50%, 100% {
    fill: #ff5f4a;
  }

  25%, 75% {
    fill: #103573;
  }
}

@keyframes estroboscopico2 {
  0%, 50%, 100% {
    fill: #17e300;
  }

  25%, 75% {
    fill: #17e300b4;
  }
}

.SevMini {
  animation: 4s ease-in-out infinite rebote;
}

#strobe_led1 {
  animation: .5s infinite estroboscopico;
}

#strobe_color1 {
  animation: .8s infinite estroboscopico2;
}

#strobe_color3 {
  animation: .8s 3s infinite estroboscopico1;
}

.loader {
  --ballcolor: #f2f2f2;
  --shadow: 0px 0 #fff0;
  --shadowcolor: #fff0;
  width: 10px;
  height: 10px;
  color: var(--ballcolor);
  border-radius: 50%;
  animation: 2s linear infinite shadowRolling;
  position: relative;
  left: -120px;
}

@keyframes shadowRolling {
  0% {
    box-shadow: var(--shadow), var(--shadow), var(--shadow), var(--shadow);
  }

  12% {
    box-shadow: 100px 0 var(--ballcolor), var(--shadow), var(--shadow), var(--shadow);
  }

  25% {
    box-shadow: 110px 0 var(--ballcolor), 100px 0 var(--ballcolor), var(--shadow), var(--shadow);
  }

  36% {
    box-shadow: 120px 0 var(--ballcolor), 110px 0 var(--ballcolor), 100px 0 var(--ballcolor), var(--shadow);
  }

  50% {
    box-shadow: 130px 0 var(--ballcolor), 120px 0 var(--ballcolor), 110px 0 var(--ballcolor), 100px 0 var(--ballcolor);
  }

  62% {
    box-shadow: 200px 0 var(--shadowcolor), 130px 0 var(--ballcolor), 120px 0 var(--ballcolor), 110px 0 var(--ballcolor);
  }

  75% {
    box-shadow: 200px 0 var(--shadowcolor), 200px 0 var(--shadowcolor), 130px 0 var(--ballcolor), 120px 0 var(--ballcolor);
  }

  87% {
    box-shadow: 200px 0 var(--shadowcolor), 200px 0 var(--shadowcolor), 200px 0 var(--shadowcolor), 130px 0 var(--ballcolor);
  }

  100% {
    box-shadow: 200px 0 var(--shadowcolor), 200px 0 var(--shadowcolor), 200px 0 var(--shadowcolor), 200px 0 var(--shadowcolor);
  }
}
/*# sourceMappingURL=index.7b7b0500.css.map */
