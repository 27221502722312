.container_SevMini {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Ghost {
    transform: translate(0px, -25px);
    z-index: -1;
    animation: opacidad 4s infinite ease-in-out;
}

@keyframes opacidad {
    0% {
        opacity: 1;
        scale: 1;
    }

    50% {
        opacity: 0.5;
        scale: 0.9;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}

@keyframes estroboscopico {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    51% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes rebote {
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes estroboscopico1 {
    0%,
    50%,
    100% {
        fill: rgb(255, 95, 74);
    }

    25%,
    75% {
        fill: rgb(16, 53, 115);
    }
}

@keyframes estroboscopico2 {
    0%,
    50%,
    100% {
        fill: #17e300;
    }

    25%,
    75% {
        fill: #17e300b4;
    }
}

.SevMini {
    animation: rebote 4s infinite ease-in-out;
}

#strobe_led1 {
    animation: estroboscopico 0.5s infinite;
}

#strobe_color1 {
    animation: estroboscopico2 0.8s infinite;
}

#strobe_color3 {
    animation: estroboscopico1 0.8s infinite;
    animation-delay: 3s;
}

/* From Uiverse.io by alexruix */
.loader {
    --ballcolor: #f2f2f2;
    --shadow: 0px 0 #ffffff00;
    --shadowcolor: #ffffff00;
    width: 10px;
    height: 10px;
    left: -120px;
    border-radius: 50%;
    position: relative;
    color: var(--ballcolor);
    animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {

    0% {
        box-shadow: var(--shadow),
        var(--shadow),
        var(--shadow),
        var(--shadow);
    }

    12% {
        box-shadow: 100px 0 var(--ballcolor),
        var(--shadow),
        var(--shadow),
        var(--shadow);
    }

    25% {
        box-shadow: 110px 0 var(--ballcolor),
        100px 0 var(--ballcolor),
        var(--shadow),
        var(--shadow);
    }

    36% {
        box-shadow: 120px 0 var(--ballcolor),
        110px 0 var(--ballcolor),
        100px 0 var(--ballcolor),
        var(--shadow);
    }

    50% {
        box-shadow: 130px 0 var(--ballcolor),
        120px 0 var(--ballcolor),
        110px 0 var(--ballcolor),
        100px 0 var(--ballcolor);
    }

    62% {
        box-shadow: 200px 0 var(--shadowcolor),
        130px 0 var(--ballcolor),
        120px 0 var(--ballcolor),
        110px 0 var(--ballcolor);
    }

    75% {
        box-shadow: 200px 0 var(--shadowcolor),
        200px 0 var(--shadowcolor),
        130px 0 var(--ballcolor),
        120px 0 var(--ballcolor);
    }

    87% {
        box-shadow: 200px 0 var(--shadowcolor),
        200px 0 var(--shadowcolor),
        200px 0 var(--shadowcolor),
        130px 0 var(--ballcolor);
    }

    100% {
        box-shadow: 200px 0 var(--shadowcolor),
        200px 0 var(--shadowcolor),
        200px 0 var(--shadowcolor),
        200px 0 var(--shadowcolor);
    }
}
