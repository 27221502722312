.border-ha {
  border-color: #49D38A;
}

.HaAuthorizeButton {
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: #FFFFFF;
  border: 0.0625rem solid #49D38A;
  //border-radius: 0.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  border-radius: var(--bs-border-radius);
}

.HaAuthorizeButton:disabled {
  background-color: #E9E9F0;
  border-color: transparent;
  cursor: not-allowed;
}

.HaAuthorizeButton:not(:disabled):focus {
  box-shadow: 0 0 0 0.25rem rgba(73, 211, 138, 0.25);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(73, 211, 138, 0.25);
}

.HaAuthorizeButtonLogo {
  padding: 0 0.8rem;
  width: 2.25rem;
}

.HaAuthorizeButtonTitle {
  background-color: #49D38A;
  color: #FFFFFF;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  padding: 0.375rem 1rem;
  line-height: var(--bs-btn-line-height);
  width: 100%;
  border-radius: 0 0.3rem 0.3rem 0;
}

.HaAuthorizeButton:disabled .HaAuthorizeButtonTitle {
  background-color: #E9E9F0;
  color: #9A9DA8;
}

.HaAuthorizeButton:not(:disabled):hover .HaAuthorizeButtonTitle,
.HaAuthorizeButton:not(:disabled):focus .HaAuthorizeButtonTitle {
  background-color: #30c677;
}

.HaAuthorizeButtonInvert {
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: #FFFFFF;
  border: 0.0625rem solid #b62c75;
  //border-radius: 0.125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  border-radius: var(--bs-border-radius);
}

.HaAuthorizeButtonInvert:not(:disabled):hover .HaAuthorizeButtonInvertTitle,
.HaAuthorizeButtonInvert:not(:disabled):focus .HaAuthorizeButtonInvertTitle {
  background-color: #cf3988;
}

.HaAuthorizeButtonInvert:disabled {
  background-color: #E9E9F0;
  border-color: transparent;
  cursor: not-allowed;
}

.HaAuthorizeButtonInvert:disabled .HaAuthorizeButtonInvertTitle {
  background-color: #E9E9F0;
  color: #9A9DA8;
}

.HaAuthorizeButtonInvert:not(:disabled):focus {
  box-shadow: 0 0 0 0.25rem rgba(182, 44, 117, 0.25);
  -webkit-box-shadow: 0 0 0 0.25rem rgba(182, 44, 117, 0.25);
}

.HaAuthorizeButtonInvertTitle {
  background-color: #b62c75;
  color: #FFFFFF;
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  padding: 0.375rem 1rem;
  line-height: var(--bs-btn-line-height);
  width: 100%;
  border-radius: 0.3rem;
}

.HaPaymentButton {
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: #FFFFFF;
  border: 0.0625rem solid #4D3FCF;
  padding: 0;
  border-radius: var(--bs-border-radius);
}

.HaPaymentButton:hover {
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  background-color: #FFFFFF;
  border: 0.0625rem solid #31278c;
  padding: 0;
  border-radius: var(--bs-border-radius);
}

.HaPaymentButton:active {
  border: 0.0625rem solid #4b40a6;
}

.HaPaymentButton:disabled {
  background-color: #E9E9F0;
  border-color: transparent;
  cursor: not-allowed;
}

//.HaPaymentButtonLogo {
//  padding: 0 0.8rem;
//  width: 2.25rem;
//}

.HaPaymentButtonTitle {
  background-color: #4D3FCF;
  color: #FFFFFF;
  padding: 0.375rem 1rem;
  line-height: var(--bs-btn-line-height);
  width: 100%;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.HaPaymentButtonTitle:hover {
  background-color: #31278c;
}

.HaPaymentButtonTitle:active {
  background-color: #4b40a6;
}

.HaPaymentButton[disabled] .HaPaymentButtonTitle {
  background-color: #E9E9F0;
  color: #9A9DA8;
}

.HaPaymentButton[disabled] > img {
  opacity: 0.5;
}

