.SingleDatePickerInput {
    border: 1px solid #6c757d !important;
    border-radius: 7px !important;
}

.SingleDatePickerInput__withBorder {
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: 7px;
    text-align: start;
    background-color: var(--bs-body-bg) !important;
}

.DateInput {
    vertical-align: middle;
    background: #fff;
    margin: 0;
    padding: 0;
    display: inline-block;
    position: relative;
    border-radius: 7px;
}

.DateInput_input {
    color: #021d2f !important;
    background-color: #fff !important;
    border: 0 !important;
    border-bottom: 2px solid #0000 !important;
    border-radius: 7px !important;
    padding: 11px 11px 9px !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    font-family: inherit !important;
    /*text-align: start;*/
    /*width: 123px;*/
    max-height: 36px !important;
    text-align: left !important;
}

.DateRangePickerInput__withBorder {
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    color: #fff !important;
    background: #000000 !important;
    border: var(--bs-border-width) double #000000 !important;
}

.DateInput_input__focused {
    background: #fff !important;
    /*border: 0;*/
    /*border-bottom: 1px solid #000000;*/
    outline: 0 !important;
    /*border-radius: 7px 7px 0 0;*/
}

.DateRangePickerInput__withBorder {
    border-radius: 7px !important;
    text-align: center !important;
    background-color: var(--bs-body-bg) !important;
    box-shadow: var(--bs-box-shadow-sm)!important;
}

.DateRangePickerInput_clearDates {
    border-left: 1px solid #6c757d !important;
    border-radius: 0 0.375rem 0.375rem 0 !important;
    padding-top: 7px !important;
    margin: auto !important;
    width: 42px !important;
}

.DateRangePickerInput_clearDates:hover {
    border-radius: 0 0.375rem 0.375rem 0 !important;
    padding-top: 7px !important;
    height: 36px !important;
    background-color: #6c757d !important;
}

.DateRangePickerInput_clearDates path:hover {
    fill:white !important;
}

.DateRangePickerInput_clearDates_svg path:hover {
    fill:white !important;
}

.DateRangePickerInput_clearDates_svg_1 path:hover {
    fill:white !important;
}

/*
DateRangePickerInput_clearDates_svg DateRangePickerInput_clearDates_svg_1
*/

@media all and (min-width:360px) {
    .DateInput_input_1 {
        border-radius: 7px !important;
        text-align: start !important;
        max-width: 115px !important;
        background-color: var(--bs-body-bg) !important;
        color: var(--bs-body-color) !important;
    }

    .DateInput {
        max-width: 115px !important;
        text-align: start !important;
        background-color: var(--bs-body-bg) !important;
    }
}

@media all and (min-width:361px) {
    .DateInput_input_1 {
        border-radius: 7px !important;
        text-align: start !important;
        max-width: 122px !important;
        background-color: var(--bs-body-bg) !important;
        color: var(--bs-body-color) !important;
    }

    .DateInput {
        max-width: 122px !important;
        text-align: start !important;
        background-color: var(--bs-body-bg) !important;
    }
}

@media all and (min-width:390px) {
    .DateInput_input_1 {
        border-radius: 7px !important;
        text-align: start !important;
        max-width: 128px !important;
        background-color: var(--bs-body-bg) !important;
        color: var(--bs-body-color) !important;
    }

    .DateInput {
        max-width: 128px !important;
        text-align: start !important;
        background-color: var(--bs-body-bg) !important;
    }
}

@media all and (min-width:414px) {
    .DateInput_input_1 {
        border-radius: 7px !important;
        text-align: start !important;
        max-width: 130px !important;
        background-color: var(--bs-body-bg) !important;
        color: var(--bs-body-color) !important;
    }

    .DateInput {
        max-width: 130px !important;
        text-align: start !important;
        background-color: var(--bs-body-bg) !important;
    }
}

@media all and (min-width:500px) {
    .DateInput_input_1 {
        border-radius: 7px !important;
        text-align: start !important;
        max-width: inherit !important;
        background-color: var(--bs-body-bg) !important;
        color: var(--bs-body-color) !important;
    }

    .DateInput {
        max-width: inherit !important;
        text-align: start !important;
        background-color: var(--bs-body-bg) !important;
    }
}

.DayPicker_transitionContainer {
    box-shadow: var(--bs-box-shadow)!important;
    max-width: 100%;
    overflow-x: hidden;
}

/*.CalendarDay__selected_span {*/
/*    color: #fff;*/
/*    background: #3c88fa;*/
/*    border: 1px double #0d6efd;*/
/*}*/

/*.DayPickerKeyboardShortcuts_show__topRight:before {*/
/*    border-bottom: 26px solid #0000;*/
/*    border-right: 33px solid #ffffff;*/
/*}*/