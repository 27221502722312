.whatsapp-button {
    color: white;
    background-color: #0f7a6e;
}

.whatsapp-button:hover {
    color: white;
    background-color: #075E54;
}

.whatsapp-button:active {
    color: white !important;
    background-color: #128C7E !important;
}