.uiverse {
    --duration: 7s;
    --easing: linear;
    --c-color-1: rgba(26, 163, 255, .7);
    /* Changed color */
    --c-color-2: #cd1aff;
    /* Changed color */
    --c-color-3: #ff1a75;
    /* Changed color */
    --c-color-4: rgba(255, 217, 26, 0.7);
    /* Changed color */
    --c-shadow: rgba(87, 223, 255, .5);
    /* Changed color */
    --c-shadow-inset-top: rgba(52, 223, 255, .9);
    /* Changed color */
    --c-shadow-inset-bottom: rgba(215, 250, 255, .8);
    /* Changed color */
    --c-radial-inner: #15d2ff;
    /* Changed color */
    --c-radial-outer: #72faff;
    /* Changed color */
    --c-color: #fff;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    outline: none;
    position: relative;
    cursor: pointer;
    border: none;
    display: table;
    border-radius: 0.25rem;;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 600;
    font-size: 0.875rem;
    letter-spacing: 0.02em;
    line-height: 1.5;
    color: #f5f6fa;
    background: radial-gradient(circle, var(--c-radial-inner), var(--c-radial-outer) 80%);
    box-shadow: 0 0 14px var(--c-shadow);
}

.uiverse:before {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.25rem;
    box-shadow: inset 0 3px 12px var(--c-shadow-inset-top), inset 0 -3px 4px var(--c-shadow-inset-bottom);
}

.uiverse:hover {
    --c-color-1: #ff5252;
    /* Changed to red */
    --c-color-2: #00bcd4;
    /* Changed to cyan */
    --c-color-3: #9c27b0;
    /* Changed to purple */
    --c-color-4: #ffeb3b;
    /*color: #222222;*/
}

.uiverse:active {
    --c-color-1: #ff5252;
    /* Changed to red */
    --c-color-2: #00bcd4;
    /* Changed to cyan */
    --c-color-3: #9c27b0;
    /* Changed to purple */
    --c-color-4: #ffeb3b;
    box-shadow: none;
    /*color: #222222;*/
}

.uiverse:disabled {
    --c-color-1: none;
    /* Changed to red */
    --c-color-2: none;
    /* Changed to cyan */
    --c-color-3: none;
    /* Changed to purple */
    --c-color-4: none;
    box-shadow: none;
    color: #636363;
    transform: none;
    animation: none;
    background: #72FAFFFF;
}

.uiverse .wrapper {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    overflow: hidden;
    border-radius: inherit;
    height: 100%;
    width: 100%;
    padding: 0.5rem 0.5rem;
}

.uiverse .wrapper span {
    display: inline-block;
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    align-content: center;
}

.uiverse .wrapper .circle {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: inherit;
    filter: blur(var(--blur, 8px));
    background: var(--background, transparent);
    transform: translate(var(--x, 0), var(--y, 0)) translateZ(0);
    animation: var(--animation, none) var(--duration) var(--easing) infinite;
}

.uiverse .wrapper .circle.circle-6, .uiverse .wrapper .circle.circle-9, .uiverse .wrapper .circle.circle-10 {
    --background: var(--c-color-4);
}

.uiverse .wrapper .circle.circle-3, .uiverse .wrapper .circle.circle-4 {
    --background: var(--c-color-2);
    --blur: 14px;
}

.uiverse .wrapper .circle.circle-5, .uiverse .wrapper .circle.circle-1 {
    --background: var(--c-color-3);
    --blur: 16px;
}

.uiverse .wrapper .circle.circle-2, .uiverse .wrapper .circle.circle-7, .uiverse .wrapper .circle.circle-8, .uiverse .wrapper .circle.circle-11, .uiverse .wrapper .circle.circle-12 {
    --background: var(--c-color-1);
    --blur: 12px;
}

.uiverse .wrapper .circle.circle-1 {
    --x: 0;
    --y: -40px;
    --animation: circle-1;
}

.uiverse .wrapper .circle.circle-2 {
    --x: 92px;
    --y: 8px;
    --animation: circle-2;
}

.uiverse .wrapper .circle.circle-3 {
    --x: -12px;
    --y: -12px;
    --animation: circle-3;
}

.uiverse .wrapper .circle.circle-4 {
    --x: 150px;
    --y: -12px;
    --animation: circle-4;
}

.uiverse .wrapper .circle.circle-5 {
    --x: 12px;
    --y: -4px;
    --animation: circle-5;
}

.uiverse .wrapper .circle.circle-6 {
    --x: 150px;
    --y: 16px;
    --animation: circle-6;
}

.uiverse .wrapper .circle.circle-7 {
    --x: 8px;
    --y: 28px;
    --animation: circle-7;
}

.uiverse .wrapper .circle.circle-8 {
    --x: 28px;
    --y: -4px;
    --animation: circle-8;
}

.uiverse .wrapper .circle.circle-9 {
    --x: 20px;
    --y: -12px;
    --animation: circle-9;
}

.uiverse .wrapper .circle.circle-10 {
    --x: 150px;
    --y: 16px;
    --animation: circle-10;
}

.uiverse .wrapper .circle.circle-11 {
    --x: 4px;
    --y: 4px;
    --animation: circle-11;
}

.uiverse .wrapper .circle.circle-12 {
    --blur: 14px;
    --x: 52px;
    --y: 4px;
    --animation: circle-12;
}

@keyframes circle-1 {
    33% {
        transform: translate(0px, 16px) translateZ(0);
    }

    66% {
        transform: translate(12px, 64px) translateZ(0);
    }
}

@keyframes circle-2 {
    33% {
        transform: translate(80px, -10px) translateZ(0);
    }

    66% {
        transform: translate(72px, -48px) translateZ(0);
    }
}

@keyframes circle-3 {
    33% {
        transform: translate(20px, 12px) translateZ(0);
    }

    66% {
        transform: translate(12px, 4px) translateZ(0);
    }
}

@keyframes circle-4 {
    33% {
        transform: translate(76px, -12px) translateZ(0);
    }

    66% {
        transform: translate(112px, -8px) translateZ(0);
    }
}

@keyframes circle-5 {
    33% {
        transform: translate(84px, 28px) translateZ(0);
    }

    66% {
        transform: translate(40px, -32px) translateZ(0);
    }
}

@keyframes circle-6 {
    33% {
        transform: translate(0px, -1px) translateZ(0);
    }

    66% {
        transform: translate(76px, -26px) translateZ(0);
    }
}

@keyframes circle-7 {
    33% {
        transform: translate(8px, 28px) translateZ(0);
    }

    66% {
        transform: translate(20px, -60px) translateZ(0);
    }
}

@keyframes circle-8 {
    33% {
        transform: translate(32px, -4px) translateZ(0);
    }

    66% {
        transform: translate(56px, -20px) translateZ(0);
    }
}

@keyframes circle-9 {
    33% {
        transform: translate(20px, -12px) translateZ(0);
    }

    66% {
        transform: translate(80px, -8px) translateZ(0);
    }
}

@keyframes circle-10 {
    33% {
        transform: translate(68px, 20px) translateZ(0);
    }

    66% {
        transform: translate(100px, 28px) translateZ(0);
    }
}

@keyframes circle-11 {
    33% {
        transform: translate(4px, 4px) translateZ(0);
    }

    66% {
        transform: translate(68px, 20px) translateZ(0);
    }
}

@keyframes circle-12 {
    33% {
        transform: translate(56px, 0px) translateZ(0);
    }

    66% {
        transform: translate(60px, -32px) translateZ(0);
    }
}
